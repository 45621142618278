import '../sass/pages/index.scoped.scss';
import React, { useMemo } from "react"
import Layout from '../components/blocks/Layout';
import Seo from '../components/Seo';
import Hello from '../components/blocks/Hello';
import Clients from '../components/blocks/Clients';
import Offers from '../components/blocks/Offers';
import Directions from "../components/blocks/Directions"
import Installation from "../components/blocks/Installation"
import Conditioners from "../components/blocks/Conditioners"
import Features from "../components/blocks/Features"
import Achievements from "../components/blocks/Achievements"
import { ModalProvider } from '../context/ModalContext';
import { graphql } from "gatsby"

const IndexPage = (props) => {

  const offers = useMemo(() => props.data.allMarkdownRemark.nodes.map(offer => ({
    ...offer.frontmatter,
    html: offer.html
  })), [props.data.allMarkdownRemark]);

  return (
    <ModalProvider>
      <Layout isMainPage={true}>
        <Seo title='ТОО Invest Group Company KZ' />
        <Hello />
        <Clients />
        <Offers offers={offers} />
        <Directions />
        <Installation />
        <Conditioners />
        <Features />
        <Achievements />
      </Layout>
    </ModalProvider>
  );
};

export default IndexPage;

export const query = graphql`
    query getOffers {
      allMarkdownRemark {
      nodes {
        frontmatter {
          preOffer
          title
          image {
            childImageSharp {
              gatsbyImageData(formats: [WEBP,AVIF, PNG], layout: CONSTRAINED, aspectRatio: 1.1, placeholder: NONE, transformOptions: { fit: CONTAIN })
            }
          }
        }
        html
      }
    }
  }
`;
