import '../../sass/components/blocks/directions.scoped.scss';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Finger from '../../icons/finger.svg';
import { useModal } from '../../context/ModalContext';
import BgBuildingEl from '../backgrounds/bg-building.el';

const Directions = () => {
  const { dispatch } = useModal();
  return (
    <BgBuildingEl id='directions-bg' Tag='section'>
      <div className='directions'>
        <h3>Наши направления</h3>
        <div className='directions-grid'>
          <div className='direction'>
            <StaticImage
              className='img'
              src='../../images/directions/bar.jpg'
              alt='Бар'
              objectFit='cover'
            />
            <h4>В барах и ресторанах</h4>
          </div>
          <div className='direction'>
            <StaticImage
              className='img'
              src='../../images/directions/market.jpg'
              alt='Торговый центр'
              objectFit='cover'
            />
            <h4>В торговых центрах и офисах</h4>
          </div>
          <div className='direction'>
            <StaticImage
              className='img'
              src='../../images/directions/auditorium.jpg'
              alt='Образовательное учреждение'
              objectFit='cover'
            />
            <h4>В образовательных учреждениях</h4>
          </div>
          <div className='direction'>
            <StaticImage
              className='img'
              src='../../images/directions/warehouse.jpg'
              alt='Склад изнутри'
              objectFit='cover'
            />
            <h4>В складских помещениях</h4>
          </div>
          <div className='direction'>
            <StaticImage
              className='img'
              src='../../images/directions/factory.jpg'
              alt='Завод конвейер'
              objectFit='cover'
            />
            <h4>В промышленных предприятиях</h4>
          </div>
          <div className='direction'>
            <StaticImage
              className='img'
              src='../../images/directions/eggs.jpg'
              alt='Аграрное предприятие'
              objectFit='cover'
            />
            <h4>В аграрном секторе</h4>
          </div>
        </div>
        <div className='directions-bottom'>
          <p>
            Наша специализация - обеспечить{' '}
            <span className='green'>свежий</span> воздух и{' '}
            <span className='accent'>приятную</span> температуру в лобом
            помещений.
          </p>
          <button
            className='btn'
            onClick={() => {
              dispatch({ type: 'open' });
            }}>
            <Finger className='icon-finger' />
            <span>Заказать расчет проекта</span>
            <span className='decoration'>
              <StaticImage
                className='shake'
                src='../../images/leaves/leaf-3.png'
                alt='Лист дерева'
                placeholder='none'
              />
            </span>
          </button>
        </div>
        <span className='decoration decoration-b'>
          <StaticImage
            className='shake'
            src='../../images/tree.png'
            alt='Дерево'
            placeholder='none'
          />
        </span>
        <span className='decoration decoration-c'>
          <StaticImage
            className='shake'
            src='../../images/leaves/leaf-3.png'
            alt='Лист дерева'
            placeholder='none'
          />
        </span>
        <span className='decoration decoration-a'>
          <StaticImage
            className='shake'
            src='../../images/mini-vent.png'
            alt='Портативный вентилятор'
            placeholder='none'
          />
        </span>
      </div>
    </BgBuildingEl>
  );
};

export default Directions;
