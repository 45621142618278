import '../../sass/components/blocks/conditioners.scoped.scss';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Finger from '../../icons/finger.svg';
import { useModal } from '../../context/ModalContext';

const Conditioners = () => {
  const { dispatch } = useModal();
  return (
    <div className='conditioners'>
      <div className='conditioners-inner'>
        <h3>
          Широкий выбор и установка кондиционеров, чиллеров, Сплит систем и
          оборудования
        </h3>
        <div className='conditioners-content'>
          <p className='conditioners-desc'>
            Всегда в наличий - кондиционеры,
            <span className='blue'> мульти и сплит-системы</span>, чиллеры,
            <span className='green'> тепловые завесы </span> и многое другое
            оборудование для
            <span className='accent'> промышленных</span> и
            <span className='green'> бытовых</span> объектов, а также их
            <span className='blue'> установка</span> и
            <span className='accent'> настройка</span>.
          </p>
          <div className='image-box'>
            <StaticImage
              src='../../images/air-conditioner.png'
              alt='Кондиционер'
              objectFit='contain'
              objectPosition='center top'
              placeholder='none'
            />
            <span className='decoration decoration-a'>
              <StaticImage
                className="shake"
                src='../../images/leaves/leaf-1.png'
                alt='Лист дерева'
                placeholder="none"
              />
            </span>
            <span className='decoration decoration-b'>
              <StaticImage
                className="shake"
                src='../../images/leaves/leaf-2.png'
                alt='Лист дерева'
                placeholder="none"
              />
            </span>
            <span className='decoration decoration-c'>
              <StaticImage
                className="shake"
                src='../../images/leaves/leaf-3.png'
                alt='Лист дерева'
                placeholder="none"
              />
            </span>
          </div>
        </div>
        <button className='btn' onClick={() => { dispatch({ type: 'open' }) }}>
          <Finger className='icon-finger' />
          <span>Узнать цены</span>
          <span className='decoration'>
            <StaticImage
              className="shake"
              src='../../images/leaves/leaf-3.png'
              alt='Лист дерева'
              placeholder="none"
            />
          </span>
        </button>
        <>
          <span className='decoration decoration-d'>
            <StaticImage
              className="shake"
              src='../../images/leaves/leaf-1.png'
              alt='Лист дерева'
              placeholder="none"
            />
          </span>
          <span className='decoration decoration-e'>
            <StaticImage
              className="shake"
              src='../../images/leaves/leaf-2.png'
              alt='Лист дерева'
              placeholder="none"
            />
          </span>
          <span className='decoration decoration-f'>
            <StaticImage
              className="shake"
              src='../../images/tree.png'
              alt='Дерево'
              placeholder="none"
            />
          </span>
          <span className='decoration decoration-g'>
            <StaticImage
              className="shake"
              src='../../images/leaves/leaf-2.png'
              alt='Лист дерева'
              placeholder="none"
            />
          </span>
        </>
      </div>
    </div>
  );
};

export default Conditioners;
