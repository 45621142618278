import '../../sass/components/blocks/features.scoped.scss';
import React from 'react';
import Talk from '../../icons/talk.svg';
import Timer from '../../icons/timer.svg';
import Star from '../../icons/star.svg';
import Group from '../../icons/group.svg';
import Calc from '../../icons/calc.svg';
import Case from '../../icons/case.svg';
import { StaticImage } from 'gatsby-plugin-image';
import Finger from "../../icons/finger.svg"
import { useModal } from '../../context/ModalContext';
import VideoMp4 from '../../videos/ventilation.mp4';
import VideoWebm from '../../videos/ventilation.webm';

const Features = () => {
  const { dispatch } = useModal();
  return (
    <div className='features'>
      <div className='features-inner'>
        <h3>Преимущества работы с нами</h3>
        <div className='features-block-wrapper'>
          <div className='features-block'>
            <div className='feature'>
              <div className='feature-icon'>
                <Talk />
              </div>
              <span>Понятный договор и простота работы</span>
            </div>
            <div className='feature'>
              <div className='feature-icon'>
                <Timer />
              </div>
              <span>100% Гарантия соблюдения сроков</span>
            </div>
            <div className='feature'>
              <div className='feature-icon'>
                <Star />
              </div>
              <span>Качественные материалы</span>
            </div>
            <div className='feature'>
              <div className='feature-icon'>
                <Group />
              </div>
              <span>Высококлассные мастера и сотрудники</span>
            </div>
            <div className='feature'>
              <div className='feature-icon'>
                <Calc />
              </div>
              <span>Строгое соблюдение бюджета</span>
            </div>
            <div className='feature'>
              <div className='feature-icon'>
                <Case />
              </div>
              <span>Комплексный спектр работ</span>
            </div>
          </div>
          <div className='quality-stamp'>
            <div className="quality-stamp-img">
              <StaticImage
                src='../../images/quality-stamp.png'
                alt='Качество гарантировано'
                placeholder='none'
              />
            </div>
          </div>
        </div>
        <video className='video' controls>
          <source src={VideoMp4} type="video/mp4" />
          <source src={VideoWebm} type="video/webm" />
        </video>
        <button className='btn' onClick={() => { dispatch({ type: 'open' }) }}>
          <Finger className='icon-finger' />
          <span>Получить консультацию</span>
          <span className='decoration'>
            <StaticImage
              className="shake"
              src='../../images/leaves/leaf-3.png'
              alt='Лист дерева'
              placeholder="none"
            />
          </span>
        </button>
        <>
          <span className='decoration decoration-a'>
            <StaticImage
              className="shake"
              src='../../images/tree.png'
              alt='Дерево'
              placeholder="none"
            />
          </span>
          <span className='decoration decoration-b'>
            <StaticImage
              className="shake"
              src='../../images/tree.png'
              alt='Дерево'
              placeholder="none"
            />
          </span>
        </>
      </div>
    </div>
  );
};

export default Features;
