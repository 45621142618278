import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const BgAchievementsEl = (props) => {
  const { mobile, tablet, desktop, wide, full } = useStaticQuery(
    graphql`
      query {
        mobile: file(relativePath: { eq: "achievments-background.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tablet: file(relativePath: { eq: "achievments-background.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktop: file(relativePath: { eq: "achievments-background.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1536) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        wide: file(relativePath: { eq: "achievments-background.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        full: file(relativePath: { eq: "achievments-background.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const sources = [
    mobile.childImageSharp.fluid,
    {
      ...tablet.childImageSharp.fluid,
      media: `(min-width: 501px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
    {
      ...wide.childImageSharp.fluid,
      media: `(min-width: 1537px)`,
    },
    {
      ...full.childImageSharp.fluid,
      media: `(min-width: 1921px)`,
    },
  ]

  return (
    <BackgroundImage {...props} fluid={sources} style={props.style}>
      {props.children && { ...props.children }}
    </BackgroundImage>
  );
};

export default BgAchievementsEl;
