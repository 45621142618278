import '../../sass/components/blocks/achievements.scoped.scss';
import '../../sass/components/blocks/achievements.scss';
import React from 'react';
import ContactsWidget from '../widgets/ContactsWidget';
import { StaticImage } from 'gatsby-plugin-image';
import BgAchievementsEl from '../backgrounds/bg-achievements.el';

const Achievements = () => {
  return (
    <BgAchievementsEl Tag='section' style={{
      overflow: 'hidden'
    }}>
      <div className='achievements'>
        <div className='achievements-block'>
          <div className='achievement'>
            <span className='value'>1000+</span>
            <span className='text'>Видов оборудования</span>
          </div>
          <div className='achievement'>
            <span className='value'>9+</span>
            <span className='text'>Лет на рынке</span>
          </div>
          <div className='achievement'>
            <span className='value'>200+</span>
            <span className='text'>Обьектов завершено</span>
          </div>
          <div className='achievement'>
            <span className='value'>999+</span>
            <span className='text'>Довольных клиентов</span>
          </div>
        </div>
        <span className='decoration decoration-a'>
          <StaticImage
            className='shake'
            src='../../images/tree.png'
            alt='Дерево'
            placeholder='none'
            objectFit='contain'
          />
        </span>
        <span className='decoration decoration-b'>
          <StaticImage
            className='shake'
            src='../../images/tree.png'
            alt='Дерево'
            placeholder='none'
            objectFit='contain'
          />
        </span>
        <ContactsWidget />
      </div>
    </BgAchievementsEl>
  );
};

export default Achievements;
