import '../../sass/components/blocks/offers.scoped.scss';
import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Finger from '../../icons/finger.svg';
import { useModal } from '../../context/ModalContext';
import BgOffersEl from '../backgrounds/bg-offers.el';
import ReactHtmlParser from 'react-html-parser';

SwiperCore.use([Autoplay]);

const Offers = ({ offers }) => {
  const { dispatch } = useModal();

  return (
    <BgOffersEl
      Tag='section'
      style={{
        backgroundAttachment: 'fixed',
      }}>
      <div className='offers'>
        <Swiper
          className='slider'
          spaceBetween={0}
          slidesPerView={1}
          autoHeight={true}
          loop={true}
          autoplay={{
            delay: 4000,
          }}>
          { offers.map(offer => {
            const img = getImage(offer.image);
            return (
              <SwiperSlide key={offer.title} className='slide'>
                <div className='values'>
                  <p className='text pre-offer'>{offer.preOffer}</p>
                  <h3 className='title'>{offer.title}</h3>
                  <div className="post-offer">
                    { ReactHtmlParser(offer.html) }
                  </div>
                  <button
                    className='btn-small'
                    onClick={() => { dispatch({ type: 'open' }); }}>
                    <Finger className='icon-finger' />
                    <span>Оставить заявку</span>
                    <span className='decoration'>
                  <StaticImage
                    className='shake'
                    src='../../images/leaves/leaf-3.png'
                    alt='Лист дерева'
                    placeholder='none'
                  />
                </span>
                  </button>
                </div>
                <div className='image'>
                  <GatsbyImage
                    image={img}
                    alt={offer.title}
                    placeholder='blurred'
                    imgStyle={{
                      objectFit: 'contain',
                      objectPosition: 'center'
                    }}
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </BgOffersEl>
  );
};

export default Offers;
