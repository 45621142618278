import '../../sass/components/blocks/installation.scoped.scss';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Finger from "../../icons/finger.svg"
import { useModal } from '../../context/ModalContext';

const Installation = () => {
  const { dispatch } = useModal();
  return (
    <div className='installation'>
      <div className='installation-inner'>
        <h3>
          Изготовление, монтаж вентиляции <br /> и тепло-охлаждающего
          оборудования
        </h3>
        <div className='installation-content'>
          <StaticImage
            className='installation-img'
            src='../../images/plan.png'
            alt='План квартиры'
            objectFit='contain'
          />
          <div className='installation-description'>
            <p>
              Учитываем <span className="accent">особенности</span> помещения и составляем <span className="blue">проект</span> с максимальной
              эффективностью, для обеспечения <span className="green">комфортной</span> температуры и потока
              воздуха
            </p>
            <button className='btn' onClick={() => { dispatch({ type: 'open' }) }}>
              <Finger className='icon-finger' />
              <span>Оставить заявку</span>
              <span className='decoration'>
                <StaticImage
                  className="shake"
                  src='../../images/leaves/leaf-3.png'
                  alt='Лист дерева'
                  placeholder="none"
                />
              </span>
            </button>
          </div>
        </div>
        <>
          <span className='decoration decoration-a'>
          <StaticImage
            src='../../images/master.png'
            alt='Мастер монтажник'
            placeholder='none'
          />
        </span>
          <span className='decoration decoration-b'>
            <StaticImage
              className="shake"
              src='../../images/tree.png'
              alt='Дерево'
              placeholder="none"
            />
        </span>
          <span className='decoration decoration-c'>
            <StaticImage
              className="shake"
              src='../../images/leaves/leaf-9.png'
              alt='Лист дерева'
              placeholder="none"
            />
        </span>
        </>
      </div>
    </div>
  );
};

export default Installation;
