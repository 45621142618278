import '../../sass/components/blocks/clients.scoped.scss';
import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { StaticImage } from 'gatsby-plugin-image';
SwiperCore.use([Autoplay]);

const Clients = () => {
  return (
    <div className='clients'>
      <div className="clients-inner">
        <h6>Среди наших клиентов</h6>
        <Swiper
          className='slider'
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 3000
          }}
          breakpoints={{
            500: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30
            },
            1366: {
              slidesPerView: 5,
              spaceBetween: 50
            },
          }}
        >
          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Kaspi.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/AsiaBank.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Assorti.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/AutoTrast.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Banzai.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Bootleger.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/CoffeeBus.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Crocus.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Diakit.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Force.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/FormaPlus.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/GruzDvorik.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Hippocrat.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/HMI.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Karcher.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/KazGidro.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/KazPost.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/KazUniversity.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/KGMC.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/KGP.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/MeatVilla.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/OhotnyiPar.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Rational.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/SadreKaraoke.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/ThreeBears.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Topar.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/VillaBorgeze.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Алатау.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="client-logo">
              <StaticImage
                src='../../images/clients/Арт.png'
                alt='wss-logo'
                placeholder="none"
              />
            </div>
          </SwiperSlide>
        </Swiper>

        <span className="decoration decoration-a">
          <StaticImage
            className="shake"
            src='../../images/leaves/leaf-1.png'
            alt='Лист дерева'
            placeholder="none"
          />
        </span>
      </div>
    </div>
  );
};

export default Clients